import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  
  return (
    <Layout>
      <SEO title="Home" />
      <section className="advertising-policy-banner background-overlay">
        <h1 className="banner-title">ADVERTISING<br />POLICY</h1>
      </section>

      <section className="advertising-policy-content">
        <p>
          Last revised on 1st March 2020
    </p>
        <div className="divider"></div>
        <h2>1. WHAT IS AN ADVERT</h2>
        <p>
          Users of Yitnow can create campaigns with the “Campaign Builder” functionality available in-app. These campaigns will send advertising messages to applicable users of the app. These campaigns and advertising messages are described for the purposes of Yitnow’s advertising policy as “adverts”.
    </p>
        <div className="divider"></div>
        <h2>2. REVIEW</h2>
        <p>
          Adverts are put through an automated screening process to check for breaches of this advertising policy or other Yitnow policies before being sent to other users. Adverts that pass the automated review process have the potential to be sent to other users. Manual review by a Yitnow employee will take place if a user who recieves an advert complains.
    </p>
        <div className="divider"></div>
        <h2>3. APPEAL</h2>
        <p>
          If you believe Cordial Radar Limited has made a mistake in the review process for your advert please <a href="/contact/">get in touch with us</a>.
    </p>
        <div className="divider"></div>
        <h2>4. CONTRAVENTION OF ADVERTISING POLICY</h2>
        <p>
          If you are deemed to have contravened the advertising policy, or any of our other policies with regard to an advert that you have sent or placed on Yitnow either by our automated review process or a manual review by an employee of Cordial Radar Limited we reserve the right to take remedial actions which may include but are not limited to rejecting the specific campaign, a warning, suspending the offending account, or terminating your account as per section 4 of Yitnow’s <a href="/terms-of-use/">Terms of use</a>.
    </p>
        <div className="divider"></div>
        <h2>5. REGARDING INDIVIDUALS OR COMPANIES</h2>
        <p>
          If an advert you place with Yitnow mentions an individual or company, by name, image or url link, then the account placing the advert must either be controlled directly by that individual or company or have the prior express written permission of that individual or company to place the advert. If prior express written permission of the individual or company is withdrawn then the advert must be withdrawn.
    </p>
        <div className="divider"></div>
        <h2>6. TAGS</h2>
        <p>
          Tags associated with an advert must be relevant to the product or service contained in the advert (to be determined at our sole discretion).
    </p>
        <div className="divider"></div>
        <h2>7. ALLOCATION</h2>
        <p>
          Each user that has an activated chat session (“type 1 user”) can recieve up to 5 adverts. Adverts are considered eligible to be sent to type 1 users when a tag or tags indicating products or services of interest to them match a tag or tags from an advert sent by a user who had set up a campaign (“type 2 user”). Adverts recieved by the type 1 user will be those eligible adverts with the highest bid prices. Type 2 users will only have coin deducted from their balance if their advert is sent to a type 1 user, the amount per advert deducted will not be more than the maximum bid set by the user in the Campaign Builder, but could be less depending on the bidding algorithm.
    </p>
        <div className="divider"></div>
        <h2>8. PROHIBITED CONTENT</h2>
        <ul>
          <li>
            Any content that breaches any other Yitnow terms and conditions including our Terms of use and Community Guidelines.
      </li>
          <li>
            Products or services that are illegal in the country or teritory in which the advert can be viewed.
      </li>
          <li>
            Products or services that are illegal in the country or territory in which they are advertised as being available.
      </li>
          <li>
            Illegal Adverts: Adverts which in the territory they are recieved advertise products that are banned from being advertised or have restrictions on the ways in which they can be advertised which would make the advert illegal
      </li>
          <li>
            Explicit Content including nudity, pornography, swearing, extreme violence etc.
      </li>
          <li>
            Bullying or malicious content.
      </li>
          <li>
            Adverts that promote financial products including loans.
      </li>
          <li>
            Adverts that promote a pyramid or multi-level marketing scheme or shemes.
      </li>
          <li>
            Ads may not promote products or services that are designed to enable a user to engage in cheating or deceitful practices.
      </li>
          <li>
            Adverts for the sale of live animals.
      </li>
          <li>
            Human body parts.
      </li>
          <li>
            Online Gambling.
      </li>
          <li>
            Lotteries.
      </li>
        </ul>
      </section>
    </Layout>
  )
};

